import React, { useState } from "react";

function Appointments() {
  const initialAppointments = [
    {
      id: 1,
      name: "John Doe",
      reason: "Check-up",
      date: "2024-11-05",
      status: "Scheduled",
    },
    {
      id: 2,
      name: "Jane Smith",
      reason: "Consultation",
      date: "2024-11-06",
      status: "Scheduled",
    },
    {
      id: 3,
      name: "Mary Johnson",
      reason: "Follow-up",
      date: "2024-11-07",
      status: "Scheduled",
    },
  ];

  const [appointments, setAppointments] = useState(initialAppointments);
  const [completedCount, setCompletedCount] = useState(0);

  const markAsCompleted = (id) => {
    const updatedAppointments = appointments.map((appointment) => {
      if (appointment.id === id && appointment.status === "Scheduled") {
        setCompletedCount(completedCount + 1);
        return { ...appointment, status: "Completed" };
      }
      return appointment;
    });
    setAppointments(updatedAppointments);
  };

  const styles = {
    appContainer: {
      backgroundColor: "#f4f6f8",
      minHeight: "100vh",
      padding: "40px 0",
      fontFamily: "Arial, sans-serif",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    header: {
      textAlign: "center",
      fontSize: "32px",
      marginBottom: "30px",
      color: "#333",
    },
    card: {
      backgroundColor: "#ffffff",
      border: "1px solid #ddd",
      padding: "15px",
      margin: "10px 0",
      maxWidth: "500px",
      borderRadius: "10px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
    name: (status) => ({
      fontSize: "20px",
      fontWeight: "bold",
      color: status === "Completed" ? "#888" : "#333",
      textDecoration: status === "Completed" ? "line-through" : "none",
    }),
    reason: {
      fontSize: "16px",
      color: "#555",
      marginTop: "5px",
    },
    date: {
      fontSize: "14px",
      color: "#777",
      marginTop: "5px",
    },
    status: {
      marginTop: "5px",
      fontSize: "16px",
      fontWeight: "bold",
      color: "#555",
    },
    button: {
      marginTop: "15px",
      padding: "10px 15px",
      cursor: "pointer",
      backgroundColor: "#5cb85c",
      color: "white",
      border: "none",
      borderRadius: "5px",
      fontSize: "14px",
    },
    footer: {
      textAlign: "center",
      marginTop: "30px",
      fontSize: "18px",
      color: "#333",
    },
  };

  return (
    <div style={styles.appContainer}>
      <h1 style={styles.header}>Patient Appointments</h1>
      {appointments.map((appointment) => (
        <div key={appointment.id} style={styles.card}>
          <div style={styles.name(appointment.status)}>{appointment.name}</div>
          <div style={styles.reason}>Reason: {appointment.reason}</div>
          <div style={styles.date}>Date: {appointment.date}</div>
          <div style={styles.status}>Status: {appointment.status}</div>
          {appointment.status === "Scheduled" && (
            <button
              style={styles.button}
              onClick={() => markAsCompleted(appointment.id)}
            >
              Mark as Completed
            </button>
          )}
        </div>
      ))}
      <div style={styles.footer}>
        Total Completed Appointments: {completedCount}
      </div>
    </div>
  );
}

export default Appointments;
